import React from "react";
import './listings.css';

function Listings() {
  return (
    <div className="container-wrapper">
      <h1 className="title">Maid List</h1>
      <div className="canva-embed">
        <iframe
          loading="lazy"
          className="canva-iframe"
          src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGNnyPJzaU&#x2F;fkgspbWV_omaDshbGjPZTQ&#x2F;view?embed"
          title="Car Listings"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      </div>
      <div className="canva-embed">
        <iframe
          loading="lazy"
          className="canva-iframe"
          src="https://www.canva.com/design/DAGVadT9N6U/kCHL2HgS_AnyGRwGr42K9Q/view?embed"
          title="Car Listings"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      </div>
    </div>
  );
}
export default Listings;